import React, { useRef, useState } from 'react'
import moment from 'moment';
import { SubmitHandler, FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import findIndex from 'lodash/findIndex'
import { OptionTypeBase } from 'react-select'

import { certificacaoStatus, CertificacaoStatusProps, EditStatusCertificacaoDataSubmit } from '../../models/StatusModel'
import { ItemProps } from '../../models/FormModel'
import { Input, Select } from '../../components/Form'
import ButtonDownloadCertificadoDigital from '../../components/ActionButton/DownloadCertificadoDigital'
import ButtonRectificationCertificacao from '../../components/ActionButton/RectificationCertificacao'
import { useCertificacao } from '../../contexts/CertificacaoSelectedContext';
import  DragDropZone from '../../components/DropZone'

interface EditStatusCertificacaoProps {
    handleSubmit: (data: EditStatusCertificacaoDataSubmit) => void;
}

const EditStatus: React.FC<EditStatusCertificacaoProps> = (props) => {

    const { certificacao } = useCertificacao()
    const [moreInputs, setMoreInputs] = useState(false)
    const [showDataStatus, setShowDataStatus] = useState(false)
    const formRef = useRef<FormHandles>(null)
    const isFinishCertification = certificacao?.status_id === 4

    const disableInputExtras = (formValue: string | undefined) => {
        return isFinishCertification && formValue ? true : false
    }

    const handleChangeStatus = (status: ItemProps | OptionTypeBase | null) => {
        status?.value === 4 ? setMoreInputs(true) : setMoreInputs(false)
        setShowDataStatus(true)
    }

    const itemsMultiSelect = (status: CertificacaoStatusProps[]) => {
        const items: ItemProps[] = [];
        status.map(item => {
            const multiSelect = {
                value: item.id,
                label: item.descricao,
            };
            return items.push(multiSelect);
        });
        return items;
    }
    const selectStatus = itemsMultiSelect(certificacaoStatus);

    const handleSubmit: SubmitHandler = data => {

        const {
            data_conclusao,
            area,
            codigo_curso_mec,
            carga_horaria,
            status_id,
            data_status,
            data_emissao,
            data_postagem,
            codigo_rastreamento,
            data_registro,
            numero_registro,
            numero_livro,
            numero_folha,
            certificado_digitalizado
        } = data;

        let statusEditData = {
            certificacao_id: certificacao?.id,
            status_id: isFinishCertification ? 4 : status_id,
        } as EditStatusCertificacaoDataSubmit

        if (data_conclusao && data_conclusao !== certificacao?.data_conclusao) statusEditData.data_conclusao = data_conclusao
        if (area && area !== certificacao?.area) statusEditData.area = area
        if (codigo_curso_mec && codigo_curso_mec !== certificacao?.codigo_curso_mec) statusEditData.codigo_curso_mec = codigo_curso_mec
        if (carga_horaria && carga_horaria !== certificacao?.carga_horaria) statusEditData.carga_horaria = carga_horaria
        if (data_status) statusEditData.data_status = data_status
        if (data_emissao) statusEditData.data_emissao = data_emissao
        if (data_postagem) statusEditData.data_postagem = data_postagem
        if (codigo_rastreamento) statusEditData.codigo_rastreamento = codigo_rastreamento
        if (data_registro) statusEditData.data_registro = data_registro
        if (numero_registro) statusEditData.numero_registro = numero_registro
        if (numero_livro) statusEditData.numero_livro = numero_livro
        if (numero_folha) statusEditData.numero_folha = numero_folha
        if (certificado_digitalizado) statusEditData.certificado_digitalizado = certificado_digitalizado[0]

        props.handleSubmit(statusEditData)
    }

    return (
        <>
            { isFinishCertification && <div className="mt-4 flex justify-center">
                    <ButtonRectificationCertificacao sizeIcon={20} certificacao={certificacao}>Retificar esse certificado</ButtonRectificationCertificacao>
                </div>
            }
            <div className="max-h-full text-sm text-gray-900">
                <Form onSubmit={handleSubmit} ref={formRef}>
                    <div className="flex-1">
                        <div className="mt-4 flex">
                            <span className="label-form">
                                Aluno:
                            </span>
                            <Input
                                type="text"
                                name="nome"
                                className="form-input-disabled"
                                value={certificacao?.aluno}
                                disabled={true}
                            />

                            <span className="label-form">
                                Data Conclusão:
                            </span>
                            <Input
                                type="date"
                                name="data_conclusao"
                                className={isFinishCertification ? 'form-input-disabled' : 'form-input'}
                                defaultValue={certificacao?.data_conclusao}
                                disabled={isFinishCertification}
                                max={moment().format("YYYY-MM-DD")}
                            />
                        </div>
                        <div className="mt-4 flex">
                            <span className="label-form">
                                Curso:
                            </span>
                            <Input
                                type="text"
                                name="curso"
                                className="form-input-disabled"
                                value={certificacao?.curso}
                                disabled={true}
                            />
                            <span className="pl-6 label-form">
                                Carga Horária:
                            </span>
                            <Input
                                type="number"
                                name="carga_horaria"
                                className={isFinishCertification ? 'form-input-disabled' : 'form-input'}
                                defaultValue={certificacao?.carga_horaria}
                                disabled={isFinishCertification}
                            />
                        </div>

                        <div className="mt-4 flex">
                            <span className="label-form">
                                Área no MEC:
                            </span>
                            <Input
                                type="text"
                                name="area"
                                className={isFinishCertification ? 'form-input-disabled' : 'form-input'}
                                defaultValue={certificacao?.area}
                                disabled={isFinishCertification}
                            />
                        </div>

                        <div className="mt-4 flex">
                            <span className="label-form">
                                Código Curso E-Mec:
                            </span>
                            <Input
                                type="text"
                                name="codigo_curso_mec"
                                className={isFinishCertification ? 'form-input-disabled' : 'form-input'}
                                defaultValue={certificacao?.codigo_curso_mec}
                                disabled={isFinishCertification}
                            />
                        </div>


                        <div className="mt-4 flex">
                            <span className="pl-2 label-form">
                                Status:
                            </span>
                            {!isFinishCertification
                                ? <Select
                                    name="status_id"
                                    options={selectStatus}
                                    className={isFinishCertification ? 'form-input-disabled' : 'form-input'}
                                    defaultValue={selectStatus[findIndex(selectStatus, { 'value': certificacao?.status_id })]}
                                    onChange={(data) => handleChangeStatus(data)}

                                />
                                : <Input
                                    type="text"
                                    disabled={isFinishCertification}
                                    name="status_id"
                                    defaultValue={selectStatus[findIndex(selectStatus, { 'value': certificacao?.status_id })].label}
                                    className="form-input-disabled"

                                />
                            }
                            {showDataStatus && <>
                                <span className="label-form">
                                    Data Status:
                                </span>
                                <Input
                                    type="date"
                                    name="data_status"
                                    defaultValue={moment().format('YYYY-MM-DD')}
                                    disabled={isFinishCertification}
                                />
                            </>
                            }
                        </div>

                        {certificacao?.status_id === 9 &&
                            <div className="mt-4 flex">
                                <span className="label-form">
                                    Justificativa Ratificação:
                                </span>
                                <Input
                                    type="text"
                                    disabled={true}
                                    name="justificativa"
                                    defaultValue={certificacao?.justificativa}
                                    className={'form-input-disabled'}

                                />
                            </div>
                        }

                        {(moreInputs || isFinishCertification) &&
                            <>
                                <div className="mt-4 flex">
                                    <span className="label-form">
                                        Data Emissão:
                                    </span>
                                    <Input
                                        type="date"
                                        disabled={disableInputExtras(certificacao?.data_emissao)}
                                        name="data_emissao"
                                        defaultValue={certificacao?.data_emissao}
                                        max={moment().format("YYYY-MM-DD")}
                                        className={disableInputExtras(certificacao?.data_emissao) ? 'form-input-disabled' : 'form-input'}

                                    />
                                    <span className="label-form">
                                        Data Postagem:
                                    </span>
                                    <Input
                                        type="date"
                                        disabled={disableInputExtras(certificacao?.data_postagem)}
                                        name="data_postagem"
                                        defaultValue={certificacao?.data_postagem}
                                        max={moment().format("YYYY-MM-DD")}
                                        className={disableInputExtras(certificacao?.data_postagem) ? 'form-input-disabled' : 'form-input'}

                                    />
                                    <span className="label-form">
                                        Código Rastreamento:
                                    </span>
                                    <Input
                                        type="text"
                                        disabled={disableInputExtras(certificacao?.codigo_rastreamento)}
                                        name="codigo_rastreamento"
                                        defaultValue={certificacao?.codigo_rastreamento}
                                        className={disableInputExtras(certificacao?.codigo_rastreamento) ? 'form-input-disabled' : 'form-input'}

                                    />
                                </div>
                                <div className="mt-4 flex">
                                    <span className="label-form">
                                        Data Registro:
                                    </span>
                                    <Input
                                        type="date"
                                        disabled={disableInputExtras(certificacao?.data_registro)}
                                        name="data_registro"
                                        defaultValue={certificacao?.data_registro}
                                        max={moment().format("YYYY-MM-DD")}
                                        className={disableInputExtras(certificacao?.data_registro) ? 'form-input-disabled' : 'form-input'}

                                    />
                                    <span className="label-form">
                                        Número Registro:
                                    </span>
                                    <Input
                                        type="text"
                                        disabled={disableInputExtras(certificacao?.numero_registro)}
                                        name="numero_registro"
                                        defaultValue={certificacao?.numero_registro}
                                        className={disableInputExtras(certificacao?.numero_registro) ? 'form-input-disabled' : 'form-input'}

                                    />
                                </div>
                                <div className="mt-4 flex">
                                    <span className="label-form">
                                        Número Livro:
                                    </span>
                                    <Input
                                        type="text"
                                        disabled={disableInputExtras(certificacao?.numero_livro)}
                                        name="numero_livro"
                                        defaultValue={certificacao?.numero_livro}
                                        className={disableInputExtras(certificacao?.numero_livro) ? 'form-input-disabled' : 'form-input'}

                                    />
                                    <span className="label-form">
                                        Número da Folha:
                                    </span>
                                    <Input
                                        type="text"
                                        disabled={disableInputExtras(certificacao?.numero_folha)}
                                        name="numero_folha"
                                        defaultValue={certificacao?.numero_folha}
                                        className={disableInputExtras(certificacao?.numero_folha) ? 'form-input-disabled' : 'form-input'}

                                    />
                                </div>
                                <div className="mt-4 flex justify-center">
                                    {!isFinishCertification || !certificacao?.certificado_digitalizado
                                        ? <DragDropZone name="certificado_digitalizado" />
                                        : certificacao?.certificado_digitalizado
                                            ? <ButtonDownloadCertificadoDigital sizeIcon={20} certificacao={certificacao}> Download do Certificado Digital </ButtonDownloadCertificadoDigital>
                                            : ''
                                    }
                                </div>
                            </>
                        }

                    </div>
                    <div className="px-4 py-3 sm:px-6 grid grid-cols-3">
                        <div className="text-red-500 col-start-1 col-end-3">
                            {
                                certificacao?.usuario
                                    ? `* Última alteração efetuada em ${moment(certificacao?.updated_at).format('LLL')} pelo agente ${certificacao?.usuario}`
                                    : ''
                            }
                        </div>
                        <div className="text-right">
                            <button type="submit" className="btn btn-indigo">
                                Salvar
                            </button>
                        </div>

                    </div>
                </Form>
            </div>
        </>
    );
};

export default EditStatus;
