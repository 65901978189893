import React from 'react'
import useSWR from 'swr'
import { useRowSelect, useTable, Column } from "react-table"
import { useSnackbar } from 'notistack'
import moment from 'moment'

import Layout from '../components/Layout'
import PageTitle from '../components/PageTitle'
import Filter from '../components/Filter'
import Divider from '../components/Divider'
import { Table } from '../components/Table';
import apiRequest, { fetcher } from '../services/http'
import { FilterData, filterInitialValues } from '../models/FilterModel'
import Pagination from '../components/Pagination'
import { certificacaoStatus, EditStatusCertificacaoDataSubmit } from '../models/StatusModel'
import ActionButtonChecked from '../components/ActionButtonChecked'
import ActionButton from '../components/ActionButton'
import { CertificacaoProps, CertificacaoTableDataProps } from '../models/CertificacaoModel'
import ModalConfirm from '../components/ModalConfirm'
import Modal from '../components/Modal'
import HistoricoEscolar from './HistoricoEscolar'
import Documentacao from './Documentation';
import PreviewCertificacao from './Certification/Preview';
import TabStatusCertificacao from './Certification/TabStatus'
import { pluck } from '../helpers'
import { useStatus } from '../contexts/StatusSelectedContext'
import { useCertificacao } from '../contexts/CertificacaoSelectedContext'
import { useCertificacaoPreview } from '../contexts/CertificacaoPreviewSelected'
import { LoadingLine as Loading } from '../components/Loading';


const Acompanhamento: React.FC = () => {

    const [search, setSearch] = React.useState<FilterData>(filterInitialValues)
    const [page, setPage] = React.useState(1)
    const [perPage, setPerPage] = React.useState(15)
    const [reload, setReload] = React.useState(false)
    const [action, setAction] = React.useState<string>('')
    const { statusId } = useStatus()
    const { enqueueSnackbar } = useSnackbar();
    const searchStatusSelected = { ...search, 'status': [statusId] }
    const { data, error } = useSWR(`/api/certificacoes?page=${page}&per_page=${perPage}&search=${JSON.stringify(statusId ? searchStatusSelected : search)}&reload=${reload}`, fetcher)
    const { certificacao, setCertificacao } = useCertificacao()
    const { certificacaoPreview, setCertificacaoPreview } = useCertificacaoPreview()

    const descricaoStatus = (status_id: number) => {
        const status = certificacaoStatus.filter((item) => {
            return item.id === status_id;
        });
        return status ? status[0].descricao : status_id;
    }

    function handleActionClick(action: string, certificacao: CertificacaoProps) {
        setCertificacao(certificacao);
        setAction(action);
    }


    const createDataToTable = (dados: CertificacaoProps[]): CertificacaoTableDataProps[] => {
        const certificacoes: CertificacaoTableDataProps[] = [];

        dados?.forEach(function (item: CertificacaoProps) {
            certificacoes.push({
                id: item.id,
                cliente_id: item.cliente_id,
                pessoa_id: item.pessoa_id,
                aluno: item.aluno.toUpperCase(),
                curso: item.curso,
                area: item.area,
                codigo_curso_mec: item.codigo_curso_mec,
                com_tcc: item.com_tcc,
                carga_horaria: item.carga_horaria,
                documentos: item.documentos,
                historico_escolar: item.historico_escolar,
                unidade: item.unidade,
                instituicao_id: item.instituicao_id,
                layout_tipo_id: item.layout_tipo_id,
                pessoa_aprovada_id: item.pessoa_aprovada_id,
                status_id: item.status_id,
                status: descricaoStatus(item.status_id),
                tipo_id: item.tipo_id,
                codigo_rastreamento: item.codigo_rastreamento,
                data_conclusao: item.data_conclusao,
                data_emissao: item.data_emissao,
                data_postagem: item.data_postagem,
                data_registro: item.data_registro,
                numero_registro: item.numero_registro,
                numero_livro: item.numero_livro,
                numero_folha: item.numero_folha,
                certificado_digitalizado: item.certificado_digitalizado,
                usuario: item.usuario,
                created_at: moment(item.created_at).format('YYYY-MM-DD'),
                updated_at: moment(item.updated_at).format('YYYY-MM-DD HH:mm:ss'),
                view: <ActionButton handleActionClick={handleActionClick} certificacao={item} />
            });
        });

        return certificacoes;
    }

    // eslint-disable-next-line
    const certificacoes = React.useMemo<CertificacaoTableDataProps[]>(() => createDataToTable(data?.data), [data?.data]);


    function handleFilter(filter: FilterData) {
        setPage(1);
        setSearch(filter);
    }

    const handleChangePage = (page: number) => setPage(page);
    const handleSetPerPage = (perPage: number) => setPerPage(perPage);

    function handleCloseModal() {
        setCertificacao(null);
        setAction('');
        setCertificacaoPreview('');
    }

    async function handleDeleteCertificacao(confirm: boolean) {
        if (confirm && certificacao) {
            try {
                await apiRequest.delete(`/api/certificacoes/${certificacao.id}`);
                data.data = certificacoes.filter(function (item) {
                    return item.id !== certificacao.id
                })
                enqueueSnackbar('Item deletado com sucesso', { variant: 'success' });
            } catch (error) {
                enqueueSnackbar('Erro ao tentar deletar item', { variant: 'error' });
            }
        }
        handleCloseModal()
    }

    async function handleEditCertificacao(dataEditCertificacao: EditStatusCertificacaoDataSubmit) {
        if (certificacao && certificacao.id === dataEditCertificacao.certificacao_id) {

            const formData = new FormData();
            formData.append('_method', 'PUT') //Laravel com formdata
            if (dataEditCertificacao.certificado_digitalizado) formData.append("certificado_digitalizado", dataEditCertificacao.certificado_digitalizado)
            if (dataEditCertificacao.area) formData.append("area", dataEditCertificacao.area)
            if (dataEditCertificacao.codigo_curso_mec) formData.append("codigo_curso_mec", dataEditCertificacao.codigo_curso_mec)
            if (dataEditCertificacao.status_id) formData.append("status_id", dataEditCertificacao.status_id.toString())
            if (dataEditCertificacao.data_conclusao) formData.append("data_conclusao", dataEditCertificacao.data_conclusao)
            if (dataEditCertificacao.carga_horaria) formData.append("carga_horaria", dataEditCertificacao.carga_horaria.toString())
            if (dataEditCertificacao.codigo_rastreamento) formData.append("codigo_rastreamento", dataEditCertificacao.codigo_rastreamento)
            if (dataEditCertificacao.data_status) formData.append("data_status", dataEditCertificacao.data_status)
            if (dataEditCertificacao.data_emissao) formData.append("data_emissao", dataEditCertificacao.data_emissao)
            if (dataEditCertificacao.data_postagem) formData.append("data_postagem", dataEditCertificacao.data_postagem)
            if (dataEditCertificacao.data_registro) formData.append("data_registro", dataEditCertificacao.data_registro)
            if (dataEditCertificacao.numero_registro) formData.append("numero_registro", dataEditCertificacao.numero_registro)
            if (dataEditCertificacao.numero_livro) formData.append("numero_livro", dataEditCertificacao.numero_livro)
            if (dataEditCertificacao.numero_folha) formData.append("numero_folha", dataEditCertificacao.numero_folha)
            if (dataEditCertificacao.justificativa) formData.append("justificativa", dataEditCertificacao.justificativa)

            const config = { headers: { 'content-type': 'multipart/form-data' } }

            try {

                await apiRequest.post(`/api/certificacoes/${certificacao.id}`, formData, config)

                data.data = certificacoes.map(function (item) {
                    if (certificacao.id === item.id) {
                        item.status_id = dataEditCertificacao.status_id
                        if (dataEditCertificacao.data_conclusao) item.data_conclusao = dataEditCertificacao.data_conclusao
                        if (dataEditCertificacao.carga_horaria) item.carga_horaria = dataEditCertificacao.carga_horaria
                        if (dataEditCertificacao.codigo_rastreamento) item.codigo_rastreamento = dataEditCertificacao.codigo_rastreamento
                        if (dataEditCertificacao.data_emissao) item.data_emissao = dataEditCertificacao.data_emissao
                        if (dataEditCertificacao.data_status) item.data_status = dataEditCertificacao.data_status
                        if (dataEditCertificacao.data_postagem) item.data_postagem = dataEditCertificacao.data_postagem
                        if (dataEditCertificacao.data_registro) item.data_registro = dataEditCertificacao.data_registro
                        if (dataEditCertificacao.numero_registro) item.numero_registro = dataEditCertificacao.numero_registro
                        if (dataEditCertificacao.numero_livro) item.numero_livro = dataEditCertificacao.numero_livro
                        if (dataEditCertificacao.numero_folha) item.numero_folha = dataEditCertificacao.numero_folha
                        if (dataEditCertificacao.justificativa) item.justificativa = dataEditCertificacao.justificativa
                    }
                    return item;
                })
                enqueueSnackbar('Alteração efetuada com sucesso', { variant: 'success' });
            } catch (erro) {
                enqueueSnackbar('Erro ao tentar alterar item', { variant: 'error' });
            }
        }
        handleCloseModal()
    }

    // eslint-disable-next-line
    const IndeterminateCheckbox = React.forwardRef(
        // @ts-ignore
        ({ indeterminate, ...rest }, ref) => {
            const defaultRef = React.useRef()
            const resolvedRef = ref || defaultRef

            React.useEffect(() => {
                // @ts-ignore
                resolvedRef.current.indeterminate = indeterminate
            }, [resolvedRef, indeterminate])

            return (
                <>
                    { /*
                    // @ts-ignore */}
                    <input type="checkbox" ref={resolvedRef} {...rest} />
                </>
            )
        }
    )


    const columns = React.useMemo<Column<CertificacaoTableDataProps>[]>(() => [
        { Header: "ID", accessor: "id" },
        { Header: "Status id", accessor: "status_id" },
        { Header: "RA", accessor: "pessoa_id" },
        { Header: "Aluno", accessor: "aluno" },
        { Header: "Curso", accessor: "curso" },
        { Header: "Conclusão", accessor: "data_conclusao", Cell: props => moment(props.value).format('L') },
        { Header: "Status", accessor: "status" },
        { Header: "Atualizado em", accessor: "updated_at", Cell: props => moment(props.value).format('L') },
        { accessor: "view" }
    ], []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        //@ts-ignore
        selectedFlatRows,
        state: {
            // @ts-ignore
            selectedRowIds,
        }
    } = useTable<CertificacaoTableDataProps>({
        columns,
        data: certificacoes,
        initialState: {
            hiddenColumns: ['id', 'status_id']
        }
    },
        useRowSelect,
        hooks => {
            //@ts-ignore
            hooks.visibleColumns.push(columns => {
                return [
                    {
                        // @ts-ignore
                        Header: ({ getToggleAllRowsSelectedProps }) => (
                            <div>
                                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                            </div>
                        ),
                        // @ts-ignore
                        Cell: ({ row }) => (
                            <div>
                                { row.values.status_id === 1 && <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />}
                            </div>
                        ),
                    },
                    ...columns,
                ]
            })
        });

    //@ts-ignore
    const selectedCertificacoes = selectedFlatRows.map(selected => selected.original).filter(item => item.status_id === 1) as CertificacaoTableDataProps[]
    async function handleActionCheckedClick(status_id: number) {

        if (selectedCertificacoes.length) {
            const getIds = pluck('id');
            const selectedCertificacoesIds = getIds(selectedCertificacoes)
            const certificacao_ids = JSON.stringify(selectedCertificacoesIds);
            try {
                await apiRequest.post(`/api/certificacoes/batch`, { certificacao_ids, status_id })

                data.data = certificacoes.map(certificacao => {
                    if (selectedCertificacoesIds.includes(certificacao.id)) {
                        certificacao.status_id = status_id
                    }
                    return certificacao;
                });
                setReload(!reload)
                enqueueSnackbar('Alteração efetuada com sucesso', { variant: 'success' });
            } catch (err) {
                enqueueSnackbar('Erro ao tentar alterar', { variant: 'error' });
            }
        } else {
            enqueueSnackbar('Selecione pelo menos um item', { variant: 'error' });
        }

    }

    return (
        <Layout>
            <PageTitle title='Acompanhamento' />
            <div className="container mx-auto px-6">
                <div className="w-full h-64 rounded">
                    <Filter handleFilter={handleFilter} />
                    {Object.keys(selectedRowIds).length !== 0 ? <ActionButtonChecked handleActionClick={handleActionCheckedClick} selectedCertificacoes={selectedCertificacoes} /> : <Divider />}

                    <Table<CertificacaoTableDataProps>
                        getTableProps={getTableProps}
                        getTableBodyProps={getTableBodyProps}
                        headerGroups={headerGroups}
                        rows={rows}
                        prepareRow={prepareRow}
                    />
                    {!data && <Loading />}
                    {error && <div className='text-center text-sm text-red-600'>Erro ao buscar dados na api</div>}
                    {Object.keys(selectedRowIds).length !== 0 && <ActionButtonChecked handleActionClick={handleActionCheckedClick} selectedCertificacoes={selectedCertificacoes} />}
                    {data?.meta && <Pagination info={data.meta} handleChangePage={handleChangePage} handleSetPerPage={handleSetPerPage} />}

                </div>
            </div>
            { action === 'delete' && certificacao &&
                <ModalConfirm handleConfirmAction={handleDeleteCertificacao} >
                    Você tem certeza que deseja deletar esse registro?
                </ModalConfirm>
            }
            { action === 'view_historico' && certificacao &&
                <Modal handleCloseModal={handleCloseModal} title={`Histórico Escolar`}>
                    <HistoricoEscolar />
                </Modal>
            }
            { action === 'view_documentacao' && certificacao &&
                <Modal handleCloseModal={handleCloseModal} title={`Documentação do aluno`}>
                    <Documentacao />
                </Modal>
            }
            { action === 'edit_certificacao' && certificacao &&
                <Modal handleCloseModal={handleCloseModal} title={`Editar status da certificação`}>
                    <TabStatusCertificacao handleSubmit={handleEditCertificacao} />
                </Modal>
            }
            { certificacaoPreview &&
                <Modal handleCloseModal={handleCloseModal} title={`Pré-Visualização do Certificado`}>
                    <PreviewCertificacao handleSubmit={handleEditCertificacao} />
                </Modal>
            }
        </Layout>
    )
}

export default Acompanhamento
